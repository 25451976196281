import React, { useEffect, useMemo, useState } from 'react';
import '../index.css';

import styled, { keyframes } from 'styled-components';
import EliteImage from '../assets/citiredesign.svg';
// Define the keyframes for the bounce animation
const flashAnimation = keyframes`
  0%, 25% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0.3;
  }
`;

const FlashingDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #3498db;
  border-radius: 50%;
  animation: ${flashAnimation} 1.5s infinite;
  animation-delay: ${(props) => props.delay}s;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const MessageText = styled.div`
  animation: flipText 3s infinite;
  @keyframes flipText {
    0% {
      opacity: 1;
    }
    33% {
      opacity: 0.5;
    }
    66% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
`;
const LoadingDots = ({ language = 'heb', direction = 'ltr' }) => {
  const allMessages = useMemo(
    () => ({
      eng: ['Please wait', 'We are working on it'],
      heb: ['אוסף מידע רלוונטי', 'מכין את התשובה'],
      arb: ['جار التحميل', 'الرجاء الانتظار', 'نحن نعمل على ذلك'],
      fra: ['Chargement', 'Veuillez attendre', 'Nous y travaillons'],
      amh: ['በመጫን ላይ', 'እባኮትን ይጠብቁ', 'እኛ በላይ እየሰራን ነን'],
      rus: ['Загрузка', 'Пожалуйста, подождите', 'Мы работаем над этим']
    }),
    []
  );

  const messages = useMemo(
    () => allMessages[language] ?? allMessages['heb'],
    [allMessages, language]
  );

  const [current, setCurrenr] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrenr((prevMessage) => (prevMessage + 1) % messages.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [messages.length]);

  return (
    <React.Fragment>
      <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
        <div className="flex gap-3 my-1 text-gray-600 text-sm w-full">
          {/* {(type === 'K2' || type === 'system') && (
        <div className="w-8 h-8">
          <div className={'rounded-full bg-gray-100 border p-1'}>
            {mainchat ? <img src={EliteImage} alt="LLM" /> : <img src={K2Image} alt="LLM" />}
          </div>
        </div>
      )} */}

          <div className="w-8 h-8">
            <div className={'rounded-full bg-gray-100 border p-1'}>
              <img src={EliteImage} alt="LLM" className="scale-150" />
            </div>
          </div>

          <p className="flex flex-col justify-start items-start w-full gap-1 mt-[5px]">
            <span className="flex font-bold text-gray-700">Citi Assistant</span>

            <div className="ml-1 text-left">
              <div className="w-fit bg-slate-100 rounded-md p-2 gap-2 flex" dir={direction}>
                <MessageText>{messages[current]}</MessageText>
                <Container>
                  <FlashingDot delay={0} />
                  <FlashingDot delay={0.5} />
                  <FlashingDot delay={1} />
                </Container>
              </div>
            </div>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoadingDots;
