// src/context/AuthContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axiosinstance from './axiosInterceptorInstance';
import { AZURE, Claude, Platform } from './PopOver';

export const getPlatformFromSelected = (selectedValue) => {
  if (selectedValue.startsWith('claude-')) {
    return Platform.CLAUDE;
  } else if (selectedValue.startsWith('IdeationChatGptO')) {
    return Platform.AZURE;
  }
  return null;
};

const AuthContext = createContext<any>({ user: {} });

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({
  children
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const [user, setUser] = useState<any>({});
  const [error, setError] = useState('');
  const [pathId, setPathId] = useState('');
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [selectedModel, setSelectedModel] = useState<string>(AZURE.IdeationChatGptO);
  const modelProvider = useMemo(
    () =>
      selectedModel
        ? selectedModel.startsWith('claude-')
          ? Platform.CLAUDE
          : selectedModel.startsWith('IdeationChatGptO')
            ? Platform.AZURE
            : null
        : null,
    [selectedModel]
  );

  const login = useCallback(async (number) => {
    const storedModel = sessionStorage.getItem('model');

    const model = storedModel || AZURE.IdeationChatGptO;
    const modelprov = getPlatformFromSelected(model);
    try {
      setIsLoadingLogin(true);
      const res = await axiosinstance.post('/session', {
        number: number,
        primary: {
          model: model,
          provider: modelprov
        },
        secondary: {
          model: model,
          provider: modelprov
        }
      });
      const { access_token, id } = res.data;
      if (access_token && id) {
        setUser({ access_token, id });
        // setCustomer(customer)
      } else {
        setError('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed');
    } finally {
      setIsLoadingLogin(false);
    }
  }, []);

  const logout = () => {
    setUser(null);
  };

  const isLoggedIn = useMemo(() => user.id && user.access_token, [user]);

  useEffect(() => {
    const model = sessionStorage.getItem('model');
    setSelectedModel(model || AZURE.IdeationChatGptO);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('model', selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    const validate = async () => {
      const pathArray = window.location.pathname.split('/');
      const id = pathArray[1];

      const res = await axiosinstance.post('./validate', {
        id: id
      });
      const { validate } = res.data;
      console.log('validate', validate);
      setPathId(id);
      setError(validate ? '' : 'url id error');
    };
    validate();
  }, []);

  console.log('error', error);
  const value = {
    user,
    error,
    login,
    logout,
    isLoadingLogin,
    isLoggedIn,
    selectedModel,
    modelProvider,
    setSelectedModel,
    pathId
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
