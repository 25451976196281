import { franc } from 'franc';
import React, { useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';

const WithoutMarkDownComp = ({ text }: { text: string }) => {
  const [direction, setDirection] = useState<'ltr' | 'rtl'>('ltr');
  const textAlign = useMemo(() => (direction === 'rtl' ? 'right' : 'left'), [direction]);

  useEffect(() => {
    const langCode = franc(text, { minLength: 1 });
    // Set the direction based on the language code
    if (langCode === 'heb' || langCode === 'arb') {
      // Hebrew or Arabic
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [text]);

  const escapeHtml = (unsafeText: string) => {
    return unsafeText.replace(/&/g, "&amp;")
                     .replace(/</g, "&lt;")
                     .replace(/>/g, "&gt;")
                     .replace(/"/g, "&quot;")
                     .replace(/'/g, "&#039;");
  };

  const processText  = (inputText: string) => {
    const escapedText = escapeHtml(inputText.trim());
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return escapedText.replace(urlRegex, (url) => {
      // const displayText = url.split('/').pop() || url;
      return `<a href="${url}" style="color: blue;"target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const processedText = processText (text?.replace(/^\s/, '').replace(/\s$/, ''));

  return (
    <div className="w-fit bg-slate-100 rounded-md">
      {/* <div
        className="whitespace-pre-line p-2 break-words"
        style={{ direction: direction, textAlign: textAlign }}>
        {text.replace(/^\s/, '').replace(/\s$/, '')}
      </div> */}
      <div
        className="whitespace-pre-line p-2 break-words"
        style={{ direction: direction, textAlign: textAlign }}
        dangerouslySetInnerHTML={{ __html: processedText }}></div>
    </div>
  );
};

export default WithoutMarkDownComp;
