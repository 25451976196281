'use client';
import React, { useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { XIcon } from 'lucide-react';
import MarkDownComp from './MarkDown';
import Message from './Message';
import axiosInstance from './axiosInterceptorInstance';
const convertTabsToSpaces = (str, spaces = 4) => str.replace(/\t/g, ' '.repeat(spaces));

export default function Bubble({
  message,
  getLongAnswers,
  status,
  divider,
  markdownDisabled,
  mainchat
}: {
  message: {
    id: string;
    have_long_answer?: boolean;
    role: string;
    prompt: string;
    time?: string;
    prompt_history?: Array<{ content: string; type: string }>;
  };
  status?: string;
  getLongAnswers?: (id: string) => Promise<any>;
  divider?: boolean;
  markdownDisabled?: boolean;
  mainchat?: boolean;
}) {
  const [longAnswer, setLongAnswer] = useState('');
  const [base64_image, setBase64_image] = useState('');
  const [showLongAnswer, setShowLongAnswer] = useState(false);

  useEffect(() => {
    if (message.have_long_answer && getLongAnswers) {
      const fetchLongAnswer = async () => {
        try {
          const response = await getLongAnswers(message.id);
          if (response.data.status === 'completed') {
            setLongAnswer(response.data.result.message);
            setBase64_image(response.data.result.base64_image);
            clearInterval(interval);
          }
        } catch (error) {
          console.error('Error fetching long answer:', error);
        }
      };

      // Start polling for the long answer
      const interval = setInterval(fetchLongAnswer, 5000);

      // Clean up interval on component unmount
      return () => clearInterval(interval);
    }
  }, [message.have_long_answer, message.id, getLongAnswers]);

  console.log('m', message.prompt_history);
  return (
    <div className="flex gap-3 my-1 text-gray-600 text-sm space-between w-full">
      {showLongAnswer && (
        <div className="fixed top-0 left-0 w-screen h-screen z-20 flex items-center justify-center inset-0 bg-black bg-opacity-80">
          <div className="bg-white rounded-lg shadow-2xl  h-[80%] w-[50%]">
            <div className="sticky left-0 top-0 w-full bg-slate-200 p-2 flex justify-between">
              <div className="font-bold ">full answer</div>
              <XIcon onClick={() => setShowLongAnswer(false)} className="w-5 h-5 cursor-pointer" />
            </div>
            <div className="overflow-auto flex flex-col gap-2 justify-start items-center p-8 h-[80%]">
              <MarkDownComp text={longAnswer} />
              {/* <Message content={longAnswer} time={''} type={'system'} /> */}
              {base64_image && (
                <img
                  src={`data:image/png;base64,${base64_image}`}
                  width="100%"
                  height="200"
                  alt="Image description"
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="w-full">
        {divider && (
          <div className="h-[2px] w-full border-t-0 shadow-sm bg-blue-500 mb-[15px]"></div>
        )}
        <Message
          markdownDisabled={markdownDisabled}
          content={message.prompt}
          time={message.time || ''}
          type={message.role}
          showLongAsnwerButton={message.have_long_answer}
          openLongAnswer={() => setShowLongAnswer(true)}
          longAnswer={longAnswer}
          mainchat={mainchat}
        />
      </div>
    </div>
  );
}
